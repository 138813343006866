import request from '@/utils/request'

// 获取首页数据
export function getHome() {
  return request({
    url: '/api/home',
    method: 'get'
  })
}

//查询融媒体矩阵数据
export function getMediaMatrix() {
  return request({
    url: '/api/getCompanyCode',
    method: 'get'
  })
}


//对应栏目详情（父级）
export function getColumns(id) {
  return request({
    url: '/cms/channel/' + id,
    method: 'get'
  })
}

//查询某个栏目下的子栏目（查询所有子栏目）
export function getSubColumns(parentId) {
  return request({
    url: '/cms/channel/list?parentId=' + parentId + '&isDel=0',
    method: 'get'
  })
}

//查询某个栏目下的内容
export function getFatherColumns(channelId, pageNum, pageSize) {
  return request({
    url: `/cms/content/list?channelId=${channelId}&articleStatus=1&pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get',
  });
}

//搜索
export function getSearch(title, pageNum, pageSize) {
  return request({
    url: `/api/findContent?title=${title}&pageNum=${pageNum}&pageSize=${pageSize}`,
    method: 'get'
  })
}

//获取内容详情接口
export function getContent(id) {
  return request({
    url: '/cms/content/' + id,
    method: 'get'
  })
}

//获取底部信息
export function getFoot() {
  return request({
    url: '/api/foot',
    method: 'get'
  })
}

//获取站点信息
export function getnavigation() {
  return request({
    url: '/api/navigation',
    method: 'get'
  })
}

// 企业简介数据
export function getCompanyProfileContetnt() {
  return request({
    url: '/api/company',
    method: 'get'
  })
}


// 业务板块数据
export function getBusinessSegmentsContent() {
  return request({
    url: '/api/business',
    method: 'get'
  })
}

//访问接口
export function accessInterface(channelId, contentId) {
  return request({
    url: `/api/visit?channelId=${channelId}&contentId=${contentId}`,
    method: 'get'
  })
}