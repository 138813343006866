import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  //企业简介
  {
    path: '/companyProfile',
    name: 'companyProfile',
    component: () => import('../views/companyProfile.vue')
  },
  //业务板块
  {
    path: '/businessSegments',
    name: 'businessSegments',
    component: () => import('../views/businessSegments.vue')
  },
  //集团动态
  {
    path: '/groupDynamics',
    name: 'groupDynamics',
    component: () => import('../views/groupDynamics.vue')
  },
  //集团动态详情
  {
    path: '/groupDynamicsDetails',
    name: 'groupDynamicsDetails',
    component: () => import('../views/groupDynamicsDetails.vue')
  },
  //党建工会
  {
    path: '/partyBuilding',
    name: 'partyBuilding',
    component: () => import('../views/partyBuilding.vue')
  },
  //党建工会详情
  {
    path: '/partyBuildingDetails',
    name: 'partyBuildingDetails',
    component: () => import('../views/partyBuildingDetails.vue')
  },
  //搜索结果
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  },
  //招商招标
  {
    path: '/informationAttract',
    name: 'informationAttract',
    component: () => import('../views/informationAttract.vue')
  },
  // 人才招聘
  {
    path: '/informationPerson',
    name: 'informationPerson',
    component: () => import('../views/informationPerson.vue')
  },
  
  //信息公开(媒体矩阵)
  {
    path: '/informationDisclosureMedia',
    name: 'informationDisclosureMedia',
    component: () => import('../views/informationDisclosureMedia.vue')
  },
  //信息公开详情（招商招标---人才招聘详情）
  {
    path: '/informationDisclosureDetails',
    name: 'informationDisclosureDetails',
    component: () => import('../views/informationDisclosureDetails.vue')
  },
  // 配置通配符路由
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router