<template>
  <div id="app">
    <el-container class="container">
      <el-header :class="{ headerActive: headerActive == 1 }">
        <div class="menu-content">
          <div class="menu-left" @click="logoClick">
            <img src="./assets/home_bannar/logo.png" alt="" class="logo-img" />
          </div>
          <div class="menu-right">
            <div class="menu-box">
              <div class="menu-ul">
                <div
                  v-for="item in menuItems"
                  :key="item.id"
                  :class="{
                    'menu-li': true,
                    active: activeIndex === item.visitPath
                  }"
                  @click.stop="handleClick(item)"
                >
                  {{ item.channelName }}
                  <div v-if="item.children" class="submenu">
                    <div
                      v-for="subItem in item.children"
                      :key="subItem.id"
                      class="submenu-item"
                      @click.stop="handleMenuClick(subItem)"
                    >
                      <div class="submenu-item-title">
                        {{ subItem.channelName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="menu-search">
              <div
                @click="SearchClick"
                :class="{
                  'serch-icon': true,
                  serchIconActive: headerActive == 1
                }"
              >
                <el-icon><Search /></el-icon>
              </div>
              <input
                type="text"
                v-model="serachValue"
                @input="removeSpaces"
                :class="{
                  'search-input': true,
                  searchInputActive: headerActive == 1
                }"
                @keyup.enter="handleSearch"
              />
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { getnavigation } from '../src/api/common'
import { events } from '../src/utils/bus'
import { ElMessage } from 'element-plus'

export default {
  name: 'app',
  data () {
    return {
      menuItems: [],
      activeIndex: '/',// 默认没有激活的菜单项
      serachValue: '',//搜索框的内容
      headerActive: 0,
    }
  },
  created () {
    this.getnavigationData()
    events.on('activeIndex', (value) => {
      this.activeIndex = value
      this.serachValue = ''
      window.scrollTo({
        top: 0,
      });
    })
    events.on('activeClass', (value) => {
      this.headerActive = value
    })
  },
  beforeDestroy () {
  },
  methods: {
    //获取首页所有数据
    getnavigationData () {
      getnavigation().then(res => {
        this.menuItems = res.data.navigation.map(item => {
          if (item.visitPath == '/' || item.visitPath == '/groupDynamics' || item.visitPath == '/partyBuilding') {
            item.children = null
          }
          return item
        })
      })
    },
    //集团logo点击
    logoClick () {
      this.$nextTick(() => {
        this.$router.push('/');
        this.activeIndex = '/'
        window.scrollTo({
          top: 0,
        }, 1);
      })
    },
    //一级导航点击
    handleClick (item) {
      if (item.linkType == 0) {
        if (item.visitPath == '/') {
          this.$router.push({
            path: item.visitPath,
          });
        } else if (item.visitPath == '/businessSegments' || item.visitPath == '/companyProfile') {
          this.$router.push({
            path: item.visitPath,
            query: {
              id: item.id,
            },
            hash: '#' + 1
          });
        } else {
          this.$router.push({
            path: item.visitPath,
            query: {
              id: item.id,
              sortNum: 1
            },
            hash: null
          });

        }
      } else {
        window.open(item.visitPath, '_blank');
      }
      this.activeIndex = item.visitPath;
    },
    // 二级导航点击
    handleMenuClick (subItem) {
      if (subItem.linkType == 0) {
        if (subItem.visitPath == '/businessSegments' || subItem.visitPath == '/companyProfile') {
          this.$router.push({
            path: subItem.visitPath,
            query: {
              id: subItem.parentId,
            },
            hash: '#' + subItem.sortNum
          });
        } else {
          this.$router.push({
            path: subItem.visitPath,
            query: {
              id: subItem.parentId,
              sortNum: subItem.sortNum,
              subId: subItem.id
            },
            hash: null
          });
        }
      } else {
        window.open(subItem.visitPath, '_blank');
      }
    },
    removeSpaces () {
      // 使用正则表达式替换空格
      this.serachValue = this.serachValue.replace(/\s/g, '');
    },
    search (title) {
      if (title == undefined || title == '') {
        ElMessage({
          message: '请输入搜索内容!',
          type: 'error',
        });
      } else {
        this.$router.push({
          path: '/search',
          query: {
            title: title
          }
        });
      }
    },
    SearchClick () {
      this.search(this.serachValue);
    },
    handleSearch () {
      this.search(this.serachValue);
    }
  }
}
</script>

<style lang="scss">
html,
body {
  width: 100%;
  margin: 0;
  font-family: STSong;
}
/* 搜索框去除边框 */
input,
button,
select {
  border: 0px;
  /* 设置背景颜色为透明 */
  background-color: transparent;
  /* 去掉外轮廓 */
  outline: none;
}
/* 去掉a的下划线 */
a {
  text-decoration: none;
}

/* 图片的空白间隙 */
img {
  vertical-align: middle;
}

#app {
}
.container {
  display: flex;
  flex-direction: column;
}

.el-header {
  color: #fff;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 104px !important;
  top: 0;
  z-index: 999;
  padding: 0 42px 0 45px !important;
  border-bottom: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.2);
  // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), transparent);
}

.el-main {
  padding: 0 !important;
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  flex: 1;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  z-index: 2;
}

//menu菜单
.menu-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .menu-left {
    cursor: pointer;
    z-index: 2;
    width: 230px;
    height: 40px;
    .logo-img {
      width: 100%;
      height: 100%;
    }
  }
  .menu-right {
    display: flex;
    align-items: center;
    height: 100%;
    .menu-box {
      display: flex;
      align-items: center;
      height: 100%;
      .menu-ul {
        display: flex;
        align-items: center;
        height: 100%;
        .menu-li {
          position: relative;
          margin: 0px 35px;
          font-size: 18px;
          color: #ffffff;
          padding-bottom: 10px;
          padding: 40px 0px;
          cursor: pointer;
          border-bottom: 4px solid transparent;
          transition: border-color 0.5s ease;
          text-shadow: 1px 1px 1px #1f100a;
          font-weight: bold;
          .submenu {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 1px solid rgba(255, 255, 255, 0.5);
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 18px;
            padding: 15px;
            margin-top: 15px;
            white-space: nowrap;
            overflow: hidden;
            visibility: hidden;
            transition: margin-top 0.5s ease-in-out;
            .submenu-item {
              .submenu-item-title {
                width: 100% !important;
                width: max-content;
                display: block;
                text-align: center;
                margin: 10px 0px;
                cursor: pointer;
                text-shadow: none;
              }
              .submenu-item-title:hover {
                color: #ba2323;
              }
            }
          }
        }
        .menu-li:hover {
          border-color: #ba2323;
        }
        .active {
          border-color: #ba2323;
        }
        .menu-li:hover > .submenu {
          //这段代码(移入Li然后展示submenu)
          // opacity: 1;
          // display: block;
          visibility: visible;
          margin-top: 2px;
          transition: margin-top 0.5s ease-in-out;
        }
      }
    }
    .menu-search {
      position: relative;
      .search-input {
        font-size: 16px;
        width: 240px;
        height: 40px;
        border-radius: 40px;
        border: 1px solid #fff;
        padding-left: 40px;
        color: rgba(255, 255, 255, 0.7);
      }
      .serch-icon {
        // height: 100%;
        font-size: 18px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 20px;
        color: #fff;
      }
    }
  }
}

.headerActive {
  background: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.5);
}

.serchIconActive {
  color: rgba(0, 0, 0, 0.7) !important;
}
.searchInputActive {
  border: 1px solid #000 !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

::v-deep .el-icon {
  width: 14px !important;
  height: 14px !important;
}
</style>
<style scoped>
</style>