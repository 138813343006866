import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入elementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

//引入fullpage.js
import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'
//引入移动端css样式
import '../src/utils/fullpages.mini.css'
import 'amfe-flexible'




const app = createApp(App)
//注册滚动监听
app.directive('scroll', {
    mounted(el, binding) {
        window.addEventListener('scroll', binding.value);
    },
    beforeUnmount(el, binding) {
        window.removeEventListener('scroll', binding.value);
    },
});


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(VueFullPage)
app.use(router)
app.use(store)
app.mount('#app')