import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    activeIndex: null,
  },
  getters: {},
  mutations: {
    setActiveIndex(state, newIndex) {
      state.activeIndex = newIndex;
    },
  },
  actions: {
    updateActiveIndex({
      commit
    }, newIndex) {
      commit('setActiveIndex', newIndex);
    },
  },
  modules: {}
})